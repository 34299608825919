@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --blue: #1a73e8;
  --dark-blue: #1F3044;
  --green: #01875f;
  --l1-green: #29de92;
  --l2-green: #bae8e8;
  --white: #fff;
  --gray: rgb(218, 218, 218);
  --l-gray: rgb(155, 157, 159);
  --red: #c71c56;
  --gold: #eaa92e;
  --background: repeating-radial-gradient(circle at 0 0, transparent 0, rgba(0, 0, 0, 0.1) 40%);
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--dark-blue);
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

/* 
::-webkit-scrollbar-thumb {
  background-color: var(--green);
} */