.container {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.fit {
  height: 100%;
  display: grid;
  place-items: center;
}

.fullscreen {
  position: absolute;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: var(--white);
}

.spinner {
  position: relative;
  width: 11.2px;
  height: 11.2px;
}

.spinner div {
  width: 100%;
  height: 100%;
  background-color: #01875f;
  border-radius: 50%;
  animation: spinner-4t3wzl 1.5s infinite backwards;
}

.spinner div:nth-child(1) {
  animation-delay: 0.18s;
  background-color: rgba(1, 135, 95, 0.9);
}

.spinner div:nth-child(2) {
  animation-delay: 0.36s;
  background-color: rgba(1, 135, 95, 0.8);
}

.spinner div:nth-child(3) {
  animation-delay: 0.54s;
  background-color: rgba(1, 135, 95, 0.7);
}

.spinner div:nth-child(4) {
  animation-delay: 0.72s;
  background-color: rgba(1, 135, 95, 0.6);
}

.spinner div:nth-child(5) {
  animation-delay: 0.8999999999999999s;
  background-color: rgba(1, 135, 95, 0.5);
}

@keyframes spinner-4t3wzl {
  0% {
    transform: rotate(0deg) translateY(-200%);
  }

  60%, 100% {
    transform: rotate(360deg) translateY(-200%);
  }
}